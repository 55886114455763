<template>
  <div>
    <b-card title="Logs">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Status</th>
            <th>Timestamp</th>
            <th>System Id</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="log in logEntries"
            :key="log.id"
          >
            <td>
              <feather-icon
                :class="getStatusClass(log.agentEventStatus)"
                icon="CircleIcon"
              />
            </td>
            <td>{{ log.timestamp | formatDateTime }}</td>
            <td>
              <b-link :to="{ name: 'asset-by-serial-number-redirect', params: { serialnumber: log.systemId }, query: {ar: true}}">
                {{ log.systemId }}
              </b-link>
            </td>
            <td>{{ log.message }}</td>
          </tr>
        </tbody>
      </table>
    </b-card>
  </div>
</template>

<script>

import { BCard, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BLink,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    logEntries() {
      if (this.asup && this.asup.details && this.asup.details.log) {
        const logs = this.asup.details.log

        // Resolve system id
        // eslint-disable-next-line no-restricted-syntax
        for (const log of logs) {
          log.systemId = this.getSystemIdByClientId(log.relatedEntityId)
        }

        return logs.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
      }

      return []
    },
  },
  methods: {
    getSystemIdByClientId(clientId) {
      const client = this.asup.details.clients.find(x => x.id === clientId)
      if (client) {
        return client.systemId
      }

      return null
    },
    getStatusClass(status) {
      switch (status) {
        case 0: return 'success'
        case 1: return 'warning'
        case 2: return 'danger'
        default: return null
      }
    },
  },
}
</script>

<style scoped>
  .info {
    fill: var(--info);
    color: var(--info);
  }
  .success {
    fill: var(--success);
    color: var(--success);
  }
  .warning {
    fill: var(--warning);
    color: var(--warning);
  }
  .danger {
    fill: var(--danger);
    color: var(--danger);
  }
</style>
